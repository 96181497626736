<template>
  <div>
    <trac-modal
      :cancel="true"
      @close="integrationViewModalCtrl = false"
      v-if="integrationViewModalCtrl"
    >
      <div class="flex flex-col items-center my-6">
        <div
          class="big-shadow rounded-lg w-32 font-bold p-6 flex flex-col items-center mx-2"
        >
          <div class="rounded-sm">
            <img :src="selectedItem.imgUrl" alt="" srcset="" />
          </div>
          <p class="mt-2 text-xs">{{ selectedItem.name }}</p>
        </div>

        <h3 class="font-bold mt-12">About {{ selectedItem.name }}</h3>
        <div class="" ref="description"></div>

        <h3 v-if="selectedItem.howItWorks" class="font-bold mt-12">
          How the integration works?
        </h3>
        <p
          v-if="selectedItem.howItWorks"
          class="text-center text-gray-800 mt-4 mx-6 text-xs"
        >
          {{ selectedItem.howItWorks }}
        </p>

        <div class="mt-10">
          <trac-button @button-clicked="openIntegrationConnection"
            >Proceed</trac-button
          >
        </div>
      </div>
    </trac-modal>
    <h3 class="text-base font-bold text-left my-5">Accounting</h3>
    <div class="flex gap-x-5">
      <div
        @click="triggerAddIntergration(item)"
        :class="item.active ? 'cursor-pointer' : ''"
        class="big-shadow rounded-lg w-32 font-bold p-6 flex flex-col items-center"
        v-for="(item, index) in integrations.allIntegrations.accounting"
        :key="index"
      >
        <div class="rounded-sm flex items-center justify-center h-10">
          <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
        </div>
        <p class="mt-2 text-xs">{{ item.name }}</p>
        <span v-if="!item.active" class="text-gray-500" style="font-size: 10px"
          >(Coming soon)</span
        >
      </div>
    </div>

    <h3 class="text-base font-bold text-left my-5">Logistics</h3>
    <div class="flex">
      <div class="flex mb-10 gap-x-5">
        <div
          @click="triggerAddIntergration(item)"
          :class="item.active ? 'cursor-pointer' : ''"
          class="big-shadow rounded-lg w-32 font-bold p-6 flex flex-col items-center"
          v-for="(item, index) in integrations.allIntegrations.logistics"
          :key="index"
        >
          <div class="rounded-sm flex items-center justify-center h-10">
            <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
          </div>
          <p class="mt-2 text-xs">{{ item.name }}</p>
          <span
            v-if="!item.active"
            class="text-gray-500"
            style="font-size: 10px"
            >(Coming soon)</span
          >
        </div>
      </div>
    </div>

    <h3 class="text-base font-bold text-left my-5">Appointments</h3>
    <div class="flex gap-x-5">
      <div
        @click="triggerAddIntergration(item)"
        :id="item.name"
        :class="item.active ? 'cursor-pointer' : ''"
        class="big-shadow rounded-lg w-32 font-bold p-6 flex flex-col items-center"
        v-for="(item, index) in integrations.allIntegrations.appointments"
        :key="index"
      >
        <div class="rounded-sm flex items-center justify-center h-10">
          <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
        </div>
        <p class="mt-2 text-xs">{{ item.name }}</p>
        <span v-if="!item.active" class="text-gray-500" style="font-size: 10px"
          >(Coming soon)</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
export default {
  data() {
    return {
      type: "myIntegration",
      isLoading: false,
      integrationViewModalCtrl: false,
      selectedStore: null,
      selectedItem: null,
      businessDetails: false,
      allowSetLoyalty: false,
      allowallIntegration: false,
      loyaltyPoints: "",
      defaultLoyalty: 0,
      integrations: null,
    };
  },
  created() {
    DELETE_LOCAL_DB_DATA("selected-integration");

    this.integrations = {
      myIntegrations: {
        accounting: [
          {
            name: "Quickbooks",
            imgUrl: "https://i.ibb.co/MPnKyqW/quickbooks.png",
            active: true,
            description:
            `<p class="text-center text-gray-800 mt-4 mx-6 text-xs">Quickbooks is an online accounting software for small businesses to accept business payments, manage and pay bills, and payroll functions.</p>`,
            howItWorks:
              "Traction is the heart of your online business, and with so much data coming through, you need a way to stay organized and keep your books up to date. When you integrate Traction with QuickBooks, your inventory, orders, customers and shipping are automatically updated and accurate.",
            pathName: "IntegrationsConnection",
          },
        ],
      },
      allIntegrations: {
        accounting: [
          {
            name: "Quickbooks",
            imgUrl: "https://i.ibb.co/MPnKyqW/quickbooks.png",
            active: true,
            description:
              `<p class="text-center text-gray-800 mt-4 mx-6 text-xs">Quickbooks is an online accounting software for small businesses to accept business payments, manage and pay bills, and payroll functions.</p>`,
            howItWorks:
              "Traction is the heart of your online business, and with so much data coming through, you need a way to stay organized and keep your books up to date. When you integrate Traction with QuickBooks, your inventory, orders, customers and shipping are automatically updated and accurate.",
            pathName: "IntegrationsConnection",
          },
          // {
          //   name: 'SME',
          //   imgUrl: 'https://i.ibb.co/t2Nd5Fj/sme.png',
          //   active: false,
          // },
          {
            name: "Xero",
            imgUrl: "https://i.ibb.co/PNFd1By/xero.png",
            active: false,
          },
        ],
        logistics: [
          {
            name: "Gokada",
            imgUrl: "https://i.ibb.co/27StWw7/gokada.png",
            active: false,
          },
          {
            name: "Kwik",
            imgUrl: "https://i.ibb.co/Fn9xjkL/kwik.png",
            active: false,
          },
        ],
        appointments: [
          {
            name: "Noona",
            imgUrl: "https://i.ibb.co/DbxPfJx/noona-logo.png",
            active: true,
            description: `<p class="text-center text-gray-800 mt-4 mx-6 text-xs">Noona is a marketplace for services and experiences. 
              It is a booking app where the merchants can manage all their bookings and customers can book for an appointment.
              This app helps to ensure that customers pay a fee or token upon 
              booking an appointment. The payment system also ensures that users' payment information is protected and secured.</p>
              
              <p class="text-center text-gray-800 mt-4 mx-6 text-xs">Click on this <a href="https://hq.noona.app/day?date=2023-10-30&resources=employees" target="_blank" class="text-primaryBlue font-bold">url</a> to create and setup your Noona store</p>`,
            pathName: "Noona",
          },
        ],
      },
    };
  },
  methods: {
    triggerAddIntergration(item) {
      if (item.active) {
        this.selectedItem = item;
        this.integrationViewModalCtrl = true;
        setTimeout(() => {
          this.$refs.description.innerHTML = this.selectedItem.description;
        }, 10);
      }
    },
    openIntegrationConnection() {
      if (this.selectedItem.pathName) {
        SAVE_LOCAL_DB_DATA("selected-integration", this.selectedItem);
        this.$router.push({ name: this.selectedItem.pathName });
      } else {
        throw new Error("No pathname specified");
      }
    },
  },
};
</script>


<style lang="postcss" scoped>
</style>